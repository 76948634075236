<template>
  <div class="contact-page">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Kontaktai / Rekvizitai</h1>
      <p class="page-subtitle">Turite klausimų? Susisiekite su mumis – mes visada pasiruošę padėti!</p>
    </div>

    <!-- Contact Section -->
    <div class="contact-details">
      <!-- Phone Section -->
      <div class="detail-section">
        <div class="icon-container">
          <i class="fa fa-phone"></i>
        </div>
        <div class="detail-content">
          <h3>Telefonai</h3>
          <p>+370 608 44230</p>
          <p>+370 698 89341</p>
        </div>
      </div>

      <!-- Email Section -->
      <div class="detail-section">
        <div class="icon-container">
          <i class="fa fa-envelope"></i>
        </div>
        <div class="detail-content">
          <h3>El. paštas</h3>
          <p>revolt.lt.info@gmail.com</p>
        </div>
      </div>

      <!-- Rekvizitai Section -->
      <div class="detail-section">
        <div class="icon-container">
          <i class="fa fa-id-card"></i>
        </div>
        <div class="detail-content">
          <h3>Rekvizitai</h3>
          <ul>
            <!-- <li><strong>Vardas:</strong> Mykolas</li>
            <li><strong>Pavardė:</strong> Sanda</li> -->
            <li><strong>Individualios veiklos numeris:</strong> 1380169</li>
            <li><strong>EVRK kodas:</strong> 4791</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
@import "/src/styles/variable";

/* Main Page Container */
.contact-page {
  padding: 2rem 1rem;
  max-width: 800px;
  margin: 0 auto;

  /* Page Header */
  .page-header {
    text-align: center;
    margin-bottom: 2rem;

    .page-title {
      font-size: 2rem;
      font-weight: bold;
      color: #2cb6d2;
      margin-bottom: 0.5rem;
    }

    .page-subtitle {
      font-size: 1.1rem;
      color: #555;
      line-height: 1.5;
    }
  }

  /* Contact Details Section */
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .detail-section {
      text-align: left !important;
      display: flex;
      align-items: center; // Vertically center the content
      gap: 1rem; // Space between icon and text
      background-color: #ffffff;
      padding: 1.5rem;
      border-radius: 12px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eaf8fc;
        color: #2cb6d2;
        width: 60px;
        height: 60px;
        border-radius: 50%;

        i {
          font-size: 1.8rem;
        }
      }

      .detail-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
          margin: 0 0 0.5rem 0;
          font-size: 1.3rem;
          color: #2cb6d2;
        }

        p,
        ul {
          margin: 0;
          font-size: 1rem;
          color: #555;
          line-height: 1.6;
        }

        ul {
          list-style-type: none;
          padding: 0;

          li {
            margin-bottom: 0.3rem;

            strong {
              color: #333;
            }
          }
        }
      }
    }

  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .page-title {
    font-size: 1.6rem;
  }

  .page-subtitle {
    font-size: 1rem;
  }

  .icon-container {
    width: 50px !important;
    height: 50px !important;

    i {
      font-size: 1.5rem !important;
    }
  }
}
</style>
